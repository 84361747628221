import React, { Component } from 'react';
import logo from './assets/logo.png';
import './App.css';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';

import firebase from "firebase";

// var firebaseConfig = {
//     apiKey: "AIzaSyAKIxRNGRpcmJ3pL9pxdeCMDdvxgcHWY6I",
//     authDomain: "cfo-web-6c315.firebaseapp.com",
//     projectId: "cfo-web-6c315",
//     storageBucket: "cfo-web-6c315.appspot.com",
//     messagingSenderId: "1053600214",
//     appId: "1:1053600214:web:69417838491680786bdc77",
//     measurementId: "G-MEBSH5KY5Z"
//   };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
//   firebase.analytics();


var firebaseConfig = {
    apiKey: "AIzaSyCg1PRPKGGa84kqRF4dU5JcPnWXoMK9_d8",
    authDomain: "cfo-service.firebaseapp.com",
    projectId: "cfo-service",
    storageBucket: "cfo-service.appspot.com",
    messagingSenderId: "478832449531",
    appId: "1:478832449531:web:ed74148d14207641f65d5e",
    measurementId: "G-X4ZR9YB35Q"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();


export default class App extends Component {

  constructor (props) {
      super(props)
      this.state = {
        name:'',
        mobile:'',
        email:'',
        emailSuccess:false
      }  

    }

    

  componentDidMount(){
     M.AutoInit();
     
     }





    validateForm(){
      var emailStatus = this.emailCheck(this.state.email)

      if (this.state.name.length==0) {
         M.toast({html: 'Name cannot be blank'})
      }else if(this.state.mobile.length!=10){
         M.toast({html: 'Mobile number must contain 10 digits'})
      }else if(emailStatus==false){
         M.toast({html: 'Invalid email'})
         
         
      }else{
        console.log("all values are true")
        this.sendEmail()
      }
    }


    emailCheck(email){
      
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());

    }

    handleName(e){
      this.setState({
        name:e.target.value
      })
    }

    handleMobile(e){
      this.setState({
        mobile:e.target.value
      })
    }

    handleEmail(e){
      this.setState({
        email:e.target.value
      })
    }


    sendEmail(){

      var mailBody = '<div>  <div style="text-align: center; margin-top:50px"> <img src="https://img.icons8.com/clouds/344/email.png" style="height: 100px; width: 100px"></td>  </div><table style="width: 100%;">  <tr  style="text-align: center;"><td style="width: 50%;" align="center"> <div><div>  <h3 style="text-align: center;border: 1px solid #26a69a; padding: 10px; color: #26a69a; width:400px">You have recieved a new CFO enquiry</h3></div><div style="text-align: center;">  '+this.state.name+' has requested an enquiry <br/>  contact: '+ this.state.mobile+'</div><div style="margin-top: 50px">  </div></div>    </td>   </tr></table>  <div style="text-align: center; background: #26a69a; margin-top:50px"> <div><div style="color: white; font-size: 18px; padding: 10px">CFO</div></div>  </div></div>'

      window.Email.send({
          Host : "smtp.gmail.com",
          Username : "notification.book.forex@gmail.com",
          Password : "@dgjadgj",
          To : 'book.forex.2021@gmail.com',
          From : "notification.book.forex@gmail.com",
          Subject : "New CFO enquiry recieved",
          Body : mailBody
      }).then(

        this.setState({
          emailSuccess: true,
          name:'',
          mobile:'',
          email:'',
        })
      );
    }

  render() {
    return (

      <div>
        <div className="row no-margin green darken-4" style={{marginTop:0}}>
          <div className="col s12 m2"></div>
          <div className="col s12 m8 flex a-center">
            <div className="col s6 t-left"><img src={logo} alt="logo" style={{height:50}}/></div>
            <div className="col s6 t-right white-text">Call us: 9323612754</div>
          </div>
          <div className="col s12 m2"></div>
        </div>

        {/*<nav className="white">
                  <div className="nav-wrapper">
                    <a className="brand-logo">
                      <img src={logo} alt="logo" style={{height:50, marginTop:5, paddingLeft:10}}/>
                    </a>
                    <ul id="nav-mobile" className="right hide-on-med-and-down" style={{paddingRight:20}}>
                      <li className="orange-text">Call us: 9323612754 </li>
                    </ul>
                  </div>
                </nav>*/}

       
        <div className="back-image row" style={{maxHeight:800}}>
          
            <div className="col s12 m12 l2"></div>

            <div className="col s12 m12 l4 t-center flex a-center" style={{height:'100%'}}>
              <div>
                <h3 className="white-text">WELCOME to</h3>
                <h3 className="white-text">Virtual CFO Service</h3>
                <div  className="white-text">
                  We provide you the right guidance to build a successful business!
                </div>
                <div  className="white-text">
                  Finance & Accounting | Risk Management | Business Advisory | Compliance
                </div>
              </div>
            </div>

            <div className=" col s12 m12 l4">
              <div className="row">
                <div className="col s12">
                  <div className="card white" style={{marginTop:10}}>
                    <div className="card-content white-text">
                      <span className="card-title black-text">Get Expert Assistance</span>
                       <div className="row" style={{marginTop:20}}>
                        <div className="input-field col s12">
                          <input placeholder="Name" id="name" type="text" value={this.state.name} onChange={(e)=>{this.handleName(e)}}/>
                          <label htmlFor="name">Full Name</label>
                        </div>
                        <div className="input-field col s12">
                          <input placeholder="Mobile" id="mobile" type="number" value={this.state.mobile} onChange={(e)=>{this.handleMobile(e)}}/>
                          <label htmlFor="mobile">Mobile</label>
                        </div>
                        <div className="input-field col s12">
                          <input placeholder="Company Email" id="email" type="text" value={this.state.email} onChange={(e)=>{this.handleEmail(e)}}/>
                          <label htmlFor="email">Email</label>
                        </div>
                        {/*<div className="g-recaptcha " data-sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" data-callback='onSubmit'         data-action='submit'></div>*/}
                        {this.state.emailSuccess?
                          <div className="teal-text t-center">
                            <img src="email.png" alt="logo" style={{height:50}}/>
                            <h5>Enquiry sent successfully</h5>
                          </div>
                         :
                          <a className="waves-effect waves-light btn-large green darken-4" style={{width:'100%'}} id="submit-button" onClick={()=>{this.validateForm()}} >Request a callback</a> 
                       }

                       </div>
                       
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>

            <div className=" col s12 m12 l2"></div>
          
        </div>


        <div className="row t-center">
          <div className="col s12 m4"></div>
          
          <div className="col s12 m4">
            <h5>RESULT ORIENTED</h5>
            <div>We are client-focused and strive to deliver more than promised.</div>

            <h5>Integrity</h5>
            <div>We commit to being reliable, dependable, and ethical with all our clients.</div>

            <h5>Collaboration</h5>
            <div>We cultivate expert connections to collude with the best minds in the business. </div>
          </div>

          <div className="col s12 m4"></div>
        </div>

        <div className="row t-center" style={{marginTop:50}}>
          <div className="col s12 m2"></div>


          <div className="col s12 m4 t-left">
            <img src={'business.jpg'} alt="logo" style={{width:'100%'}}/>
            <h5>WHY CHOOSE CFOservice.in?</h5>
            <div>CFOservice.in is an expert at handling the Financial Management of a business.</div>

            <ul className="t-left">
                <li>- Elite Finance Experts</li>
                <li>- Tailor-made Packages</li>
                <li>- Affordable Cost</li>
                <li>- Outstanding Value</li>
                <li>- Execution Focused</li>
                <li>- Quick Turnaround</li>
              </ul>

          </div>
          
          <div className="col s12 m4">
            <img src={'meeting.jpg'} alt="logo" style={{width:'100%'}}/>
            <h5>We Partner in</h5>
            <ul className="t-left">
                <li>- Accounting and Business Support</li>
                <li>- IFRS Expert</li>
                <li>- Indirect tax – GST</li>
                <li>- Direct tax</li>
                <li>- Valuation</li>
               <li>- Corporate Law – CS</li>
                <li>- Advocate</li>
              </ul>
          </div>

          <div className="col s12 m2"></div>
        </div>



        



        <div className="row t-center">
          <div className="col s12 m4"></div>
          
          <div className="col s12 m4">
            <h5>Outsourced CFO Services</h5>

            <div className="t-left">We provide as-needed full-time, part-time, or short-term CFO services to businesses.
              Preferred CFO has an elite team of some of the most experienced outsourced CFOs in the industry. These experts have decades of experience providing CFO services to hundreds of clients in dozens and industries.
              </div>
            <ul className="t-left">
                <li>- Financial Strategy</li>
                <li>- Short- and Long-Term Forecasting</li>
                <li>- Financial Systems Strategy & Design</li>
                <li>- Facilitating & Interpreting Financial Reporting</li>
                <li>- Raising Capital and advisory on Capital Structure</li>
                <li>- Cash Flow Analysis & Restructuring</li>
                <li>- Making Cost Cuts</li>
                <li>- Facilitating Mergers & Acquisitions</li>
                <li>- Manpower support</li>
              </ul>

              <div className="t-left">
                Assisting in finding the best fit for the job, leadership hiring. Satisfying urgent requirements including providing staff on temporary basis
              </div>

           
          </div>

          <div className="col s12 m4"></div>
        </div>




        <div className="back-image-bottom flex a-center j-center" style={{height:200}}>
          <div className="t-center">
            <h5 className="white-text">SCHEDULE A FREE CONSULTATION NOW</h5>
            <a href="mailto:book.forex.2021@gmail.com" className="waves-effect waves-light btn green darken-4" style={{}} id="submit-button" >Request a callback</a>
          </div>
        </div>

        

        <footer class="page-footer green darken-4">
          <div class="container">
            <div class="row no-margin">
              <div class="col l6 s12" style={{paddingBottom:20}}>
                <img src={logo} alt="logo" style={{height:50}}/>
                <div style={{marginTop:30}}>The Financial services offered by</div>
                <div>FXunlimited Financial Services Pvt. Ltd.</div>
                <div>FXunlimited.india@gmail.com</div>

              </div>
              <div class="col l4 offset-l2 s12 t-right" style={{paddingBottom:20}}>
                <div className="t-right white-text">Call us: 9323612754</div>

                <div style={{marginTop:30}}>CFOservice.in</div>
                <div>Office no. 104, Shreeji Vihar,</div>
                <div>Opera House, Charni Road, Mumbai-400 004</div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    )
  }
}